<template>
  <div id="app">
    <div class="main">
      <div style="margin-top: 1rem">
        <el-main style="width: 1000px;margin: 0 auto;background: #fff">
          <template v-if="!showDetailPage">
            <el-tabs v-model="selectTab" style="height: 100%" tab-position="left" stretch @tab-click="getDataList(true)">
              <el-tab-pane label="新闻动态" name="news">
                <el-table
                  class="notice"
                  style="width: 100%"
                  size="small"
                  :show-header="false"
                  v-loading="dataListLoading"
                  :data="dataList">
                  <el-table-column
                    prop="newsName"
                    label="标题"
                    header-align="left"
                    align="left">
                    <template slot-scope="scope">
                      <a @click="showDetail(scope.row)">{{ scope.row.newsName }}</a>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="createTime"
                    label="创建时间"
                    header-align="right"
                    width="200"
                    align="right">
                    <template slot-scope="scope">
                      {{ formatDate(scope.row.createTime) }}
                    </template>
                  </el-table-column>
                </el-table>
                <div class="tar mt-2">
                  <el-pagination
                    :page-size="pageSize"
                    :current-page="pageIndex"
                    background
                    @current-change="currentChangeHandle"
                    layout="total,prev,pager,next,jumper" :total="totalPage">
                  </el-pagination>
                </div>
              </el-tab-pane>
              <el-tab-pane label="通知公告" name="notice">
                <el-table
                  class="notice"
                  style="width: 100%"
                  size="small"
                  :show-header="false"
                  v-loading="dataListLoading"
                  :data="dataList">
                  <el-table-column
                    prop="noticeName"
                    label="标题"
                    header-align="left"
                    align="left">
                    <template slot-scope="scope">
                      <a @click="showDetail(scope.row)">{{ scope.row.noticeName }}</a>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="createTime"
                    label="创建时间"
                    width="200"
                    header-align="right"
                    align="right">
                    <template slot-scope="scope">
                      {{ formatDate(scope.row.createTime) }}
                    </template>
                  </el-table-column>
                </el-table>
                <div class="tar mt-2">
                  <el-pagination
                    :page-size="pageSize"
                    :current-page="pageIndex"
                    background
                    @current-change="currentChangeHandle"
                    layout="total,prev,pager,next,jumper" :total="totalPage">
                  </el-pagination>
                </div>
              </el-tab-pane>
            </el-tabs>
          </template>
          <template v-else>
            <el-scrollbar style="height: 100%">
              <news-notice-page-detail :data="currentObj" :type="selectTab" @closeCallback="closeCallback"></news-notice-page-detail>
            </el-scrollbar>
          </template>
        </el-main>
      </div>
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import {checkIsNull} from '../../utils'
  import moment from 'moment'
  import NewsNoticePageDetail from './news-notice-page-detail'
  export default {
    name: 'news-notice-page',
    components: {
      NewsNoticePageDetail
    },
    data () {
      return {
        selectTab: 'news',
        showDetailPage: false,
        currentObj: {},
        dataListLoading: false,
        dataList: [],
        pageIndex: 1,
        pageSize: 15,
        totalPage: 0,
        isRouteJump: false
      }
    },
    computed: {
      formatDate () {
        return function (date) {
          return moment(date).format('YYYY-MM-DD')
        }
      }
    },
    created () {
      const _params = this.$route.params
      if (!checkIsNull(_params.tab)) {
        this.selectTab = _params.tab
      }
      if (!checkIsNull(_params.obj)) {
        this.showDetail(_params.obj)
        this.isRouteJump = true
      }
      this.getDataList(true)
    },
    methods: {
      getDataList (reset) {
        this.dataListLoading = true
        if (reset) {
          this.pageIndex = 1
        }
        this.$http({
          url: this.$http.adornUrl(this.selectTab === 'news' ? this.$api.NEWS.LIST : this.$api.NOTICE.LIST),
          method: 'post',
          params: this.$http.adornParams({
            'page': this.pageIndex,
            'limit': this.pageSize,
            'newsStatus': 1,
            'noticeStatus': 1
          })
        }).then(({data}) => {
          this.dataListLoading = false
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
        })
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList(false)
      },
      // 展示详情
      showDetail (data) {
        this.showDetailPage = true
        this.currentObj = this.$deepClone(data)
      },
      // 关闭回调
      closeCallback () {
        if (this.isRouteJump) {
          this.$router.go(-1)
        } else {
          this.showDetailPage = false
          this.currentObj = {}
        }
      }
    }
  }
</script>

<style>
 .el-main {
   height: calc(100vh - 170px)
 }
 .notice {
   font-size: 14px
 }
 .notice a {
   cursor: pointer;
   text-decoration: none;
   color: #333333;
   font-size: 14px;
   margin-left: 5px
 }
 .notice a:hover {
   color: #327ac7;
   text-decoration: underline
 }
 .el-scrollbar__wrap {
   overflow-y: scroll;
   overflow-x: hidden;
   height: 100%
 }
</style>
