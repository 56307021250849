<template>
  <div>
    <el-page-header title="返回" @back="$emit('closeCallback')">
      <span slot="content" class="fwb">{{ type === 'news' ? '新闻详情' : '通知/公告详情' }}</span>
    </el-page-header>
    <el-divider></el-divider>
    <div style="width: 851px;margin: 0 auto;">
      <div style="font-size: 40px;color: black;line-height: 58px;font-weight: bold;padding-top: 41px;padding-bottom: 21px;">{{ type === 'news' ? data.newsName : data.noticeName }}</div>
      <div style="color: #888;padding-bottom: 20px;border-bottom: 1px solid #ddd;font-size: 14px">
        <span>发布时间：{{ data.createTime }}</span>
        <span class="ml-4" v-if="type === 'news'">新闻类别：{{ newsDictMap[data.newsType] }}</span>
        <span class="ml-4" v-if="type === 'notice'">通知类别：{{ noticeDictMap[data.noticeType] }}</span>
      </div>
      <div style="margin-top: 20px;padding-bottom: 20px">
        <my-editor-view v-if="type === 'news'" :content="data.newsObj"></my-editor-view>
        <my-editor-view v-if="type === 'notice'" :content="data.noticeObj"></my-editor-view>
      </div>
      <div class="uf uf-ac uf-pj mb-5" v-if="type === 'news'">
        <el-link type="info" v-if="data.newsResource && data.newsResource !== ''" :href="decodeResource(data.newsResource)" target="_blank">查看原文</el-link>
        <el-link v-if="data.newsAttach && data.newsAttach !== ''" type="primary" icon="el-icon-download" :underline="false" @click="downloadFile(data.newsAttach)">点此下载附件</el-link>
      </div>
      <div class="uf uf-ac uf-pj mb-5" v-if="type === 'notice'">
        <el-link type="info" v-if="data.noticeResource && data.noticeResource !== ''" :href="decodeResource(data.noticeResource)" target="_blank">查看原文</el-link>
        <el-link v-if="data.noticeAttach && data.noticeAttach !== ''" type="primary" icon="el-icon-download" :underline="false" @click="downloadFile(data.noticeAttach)">点此下载附件</el-link>
      </div>
    </div>
  </div>
</template>

<script>
  import MyEditorView from '../../components/my-editor-view'
  export default {
    name: 'news-notice-page-detail',
    components: {MyEditorView},
    props: {
      data: {
        type: Object,
        required: true
      },
      type: {
        type: String,
        default: 'news'
      }
    },
    data () {
      return {
        newsDictMap: {},
        noticeDictMap: {}
      }
    },
    computed: {
      decodeResource () {
        return function (resource) {
          return decodeURI(resource)
        }
      }
    },
    created() {
      this.$getDictMap('NEWS_TYPE,NOTICE_TYPE', (dictMap) => {
        this.newsDictMap = dictMap.NEWS_TYPE
        this.noticeDictMap = dictMap.NOTICE_TYPE
      })
    },
    methods: {
      // 下载
      downloadFile (path) {
        const downloadPath = this.$cons.SERVER_PATH + path
        let elemIF = document.createElement('iframe')
        elemIF.src = downloadPath
        elemIF.style.display = 'none'
        document.body.appendChild(elemIF)
      }
    }
  }
</script>

<style scoped>

</style>
